import { configureStore } from '@reduxjs/toolkit';
import searchReducer from './searchSlice';
import authReducer from './authSlice';
import listingsReducer from './listingSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    search: searchReducer,
    listings: listingsReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});
