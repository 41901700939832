// File: src/components/Homepage/Home.js

import React, { useRef } from 'react';
import { Typography, Box, AppBar, Toolbar, Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import backgroundVideo from '../../assets/background.mov';
import rishi from '../../assets/rishi.jpeg';
import jay from '../../assets/jay.jpeg';
import soham from '../../assets/soham.jpeg';
import rushank from '../../assets/rushank.jpeg';
import '@fontsource/plus-jakarta-sans'; // Ensure the font is imported
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const Home = () => {
  const aboutRef = useRef(null);
  const contactRef = useRef(null);

  const handleScrollToSection = (sectionRef) => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      {/* Hero Section */}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        {/* Navigation Bar */}
        <AppBar
          elevation={0}
          sx={{
            backgroundColor: 'transparent',
            boxShadow: 'none',
            position: 'absolute',
            top: 0,
          }}
        >
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            {/* Left-aligned buttons */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button
                onClick={() => handleScrollToSection(aboutRef)}
                sx={{
                  fontWeight: 600,
                  fontFamily: '"Plus Jakarta Sans", sans-serif',
                  textTransform: 'none',
                  ml: 2,
                  fontSize: '28px',
                  background: 'linear-gradient(135deg, #6FA8DC 0%, #1A365D 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    opacity: 0.8,
                  },
                }}
              >
                About
              </Button>
              <Button
                onClick={() => handleScrollToSection(contactRef)}
                sx={{
                  fontWeight: 600,
                  fontFamily: '"Plus Jakarta Sans", sans-serif',
                  textTransform: 'none',
                  ml: 2,
                  fontSize: '28px',
                  background: 'linear-gradient(135deg, #6FA8DC 0%, #1A365D 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    opacity: 0.8,
                  },
                }}
              >
                Contact
              </Button>
              <Button
                component={Link}
                to="/demo"
                sx={{
                  fontWeight: 600,
                  fontFamily: '"Plus Jakarta Sans", sans-serif',
                  textTransform: 'none',
                  ml: 2,
                  fontSize: '28px',
                  background: 'linear-gradient(135deg, #6FA8DC 0%, #1A365D 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    opacity: 0.8,
                  },
                }}
              >
                Demo
              </Button>
            </Box>
            {/* Right-aligned Login button */}
            <Button
              component={Link}
              to="/login"
              sx={{
                fontWeight: 600,
                fontFamily: '"Plus Jakarta Sans", sans-serif',
                textTransform: 'none',
                mr: 2,
                fontSize: '28px',
                background: 'linear-gradient(135deg, #6FA8DC 0%, #1A365D 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'transparent',
                  opacity: 0.8,
                },
              }}
            >
              Login
            </Button>
          </Toolbar>
        </AppBar>

        {/* Background Video */}
        <Box
          component="video"
          autoPlay
          loop
          muted
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            minWidth: '100%',
            minHeight: '100%',
            width: 'auto',
            height: 'auto',
            zIndex: -1,
            transform: 'translate(-50%, -50%)',
          }}
        >
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </Box>

        {/* Overlay Content */}
        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#FFFFFF',
            textAlign: 'center',
            p: 2,
          }}
        >
          <Box
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.001)',
              padding: '16px',
              borderRadius: '8px',
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: '64px', md: '96px', lg: '128px' },
                fontWeight: 800,
                background: 'linear-gradient(135deg, #6FA8DC 0%, #1A365D 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontFamily: '"Plus Jakarta Sans", sans-serif',
                letterSpacing: '-2px',
              }}
            >
              Intratalent AI
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: '24px', md: '36px', lg: '48px' },
                fontStyle: 'italic',
                background: 'linear-gradient(135deg, #6FA8DC 0%, #1A365D 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontFamily: '"Plus Jakarta Sans", sans-serif',
                marginTop: '16px',
              }}
            >
              Hiring Simplified
            </Typography>
          </Box>
          <ArrowDownwardIcon
            onClick={() => handleScrollToSection(aboutRef)}
            sx={{
              position: 'absolute',
              bottom: '20px',
              left: '50%',
              transform: 'translateX(-50%)',
              color: '#FFFFFF',
              fontSize: '48px',
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.8,
              },
            }}
          />
        </Box>
      </Box>

      {/* About Section */}
      <Box
        ref={aboutRef}
        sx={{
          padding: '80px 16px',
          backgroundColor: '#f8faff',
          fontFamily: '"Plus Jakarta Sans", sans-serif',
        }}
      >
        <Typography
          sx={{
            fontSize: '48px',
            fontWeight: 700,
            color: '#1A365D',
            marginBottom: '32px',
            textAlign: 'center',
          }}
        >
          About Us
        </Typography>
        <Typography
          sx={{
            fontSize: '20px',
            color: '#4A5568',
            lineHeight: 1.8,
            maxWidth: '800px',
            margin: '0 auto',
            textAlign: 'center',
            marginBottom: '64px',
          }}
        >
          Born out of our Stanford dorm rooms, Intratalent AI is revolutionizing the recruitment industry by leveraging advanced artificial intelligence to streamline the hiring process. Our platform intelligently matches top-tier talent with organizations, simplifying the recruitment journey for both employers and candidates.
        </Typography>

        {/* Founders Profiles */}
        <Grid container spacing={6}>
          {/* Founder Cards */}
          {[
            {
              name: 'Rishi Sadanandan',
              title: 'Co-Founder & CEO',
              image: rishi,
            },
            {
              name: 'Jay Khemchandani',
              title: 'Co-Founder & CTO',
              image: jay,
            },
            {
              name: 'Soham Vasanawala',
              title: 'Co-Founder & Head of Product',
              image: soham,
            },
            {
              name: 'Rushank Goyal',
              title: 'Co-Founder & COO',
              image: rushank,
            },
          ].map((founder, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box
                sx={{
                  textAlign: 'center',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                  },
                }}
              >
                <Box
                  component="img"
                  src={founder.image}
                  alt={founder.name}
                  sx={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '50%',
                    marginBottom: '16px',
                  }}
                />
                <Typography sx={{ fontSize: '20px', fontWeight: 600, color: '#1A365D' }}>
                  {founder.name}
                </Typography>
                <Typography sx={{ fontSize: '16px', color: '#2B4C8C' }}>
                  {founder.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Contact Section */}
      <Box
        ref={contactRef}
        sx={{
          padding: '80px 16px',
          backgroundColor: '#1A365D',
          color: '#ffffff',
          textAlign: 'center',
          fontFamily: '"Plus Jakarta Sans", sans-serif',
        }}
      >
        <Typography
          sx={{
            fontSize: '48px',
            fontWeight: 700,
            marginBottom: '32px',
          }}
        >
          Get in Touch
        </Typography>
        <Typography
          sx={{
            fontSize: '20px',
            lineHeight: 1.6,
            maxWidth: '800px',
            margin: '0 auto',
            marginBottom: '64px',
            fontWeight: 'bold',
          }}
        >
          Reach out to us at:{' '}
          <a
            href="mailto:contact@intratalent.ai"
            style={{ color: '#FFFFFF', textDecoration: 'none' }}
          >
            contact@intratalent.ai
          </a>
        </Typography>
      </Box>
    </div>
  );
};

export default Home;