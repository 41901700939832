import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Grid,
  IconButton,
  Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import UploadIcon from '@mui/icons-material/Upload';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { motion } from 'framer-motion';
import { 
  fetchListings, 
  initiateListingCreation,
  completeListingCreation,
  updateListing, 
  deleteListing,
  clearPaymentIntent 
} from '../../services/listingSlice';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

// Payment Form Component
const PaymentForm = ({ onSuccess, onError, listingData }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!stripe || !elements) return;

    setProcessing(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required'
      });

      if (error) {
        onError(error.message);
        return;
      }

      // Payment successful, create listing
      await dispatch(completeListingCreation({
        paymentIntentId: paymentIntent.id,
        listingData
      })).unwrap();
      
      onSuccess();
    } catch (err) {
      onError(err.message);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        type="submit"
        disabled={!stripe || processing}
        variant="contained"
        fullWidth
        sx={{ mt: 2 }}
      >
        {processing ? 'Processing...' : 'Pay and Create Listing'}
      </Button>
    </form>
  );
};

const StyledContainer = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg, #f8faff 0%, #f0f4fb 100%)',
  minHeight: '100vh',
  padding: theme.spacing(4),
  fontFamily: '"Plus Jakarta Sans", sans-serif',
}));

const ListingCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: '16px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
  '&:hover': {
    boxShadow: '0 6px 8px rgba(0, 0, 0, 0.1)',
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '8px',
  padding: '8px 16px',
  fontWeight: 600,
}));

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { companyName } = useSelector((state) => state.auth);
  const { 
    list: listings, 
    loading, 
    error,
    paymentIntent,
    pendingListingData 
  } = useSelector((state) => state.listings);
  
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingListing, setEditingListing] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    job_description: '',
    namespace: 'all'
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [listingToDelete, setListingToDelete] = useState(null);

  useEffect(() => {
    dispatch(fetchListings());
  }, [dispatch]);

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setEditingListing(null);
    setFormData({ title: '', job_description: '', namespace: 'all' });
    dispatch(clearPaymentIntent());
  };

  const handleEditListing = (listing) => {
    setEditingListing(listing);
    setFormData({
      title: listing.title,
      job_description: listing.job_description,
      namespace: listing.namespace
    });
    setDialogOpen(true);
  };

  const handleDeleteClick = (listing) => {
    setListingToDelete(listing);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (listingToDelete) {
      await dispatch(deleteListing(listingToDelete.id));
      setDeleteDialogOpen(false);
      setListingToDelete(null);
    }
  };

  const validateForm = () => {
    if (!formData.title.trim()) {
      return 'Job title is required';
    }
    if (!formData.job_description.trim()) {
      return 'Job description is required';
    }
    return null;
  };

  const handleSubmit = async () => {
    const validationError = validateForm();
    if (validationError) {
      console.error(validationError);
      return;
    }
    
    try {
      if (editingListing) {
        await dispatch(updateListing({
          listingId: editingListing.id,
          listingData: formData
        })).unwrap();
        dispatch(fetchListings());
        handleCloseDialog();
      } else {
        // Initialize payment intent
        console.log('creating listing with payment intent')
        await dispatch(initiateListingCreation(formData)).unwrap();
      }
    } catch (err) {
      console.error('Failed to save listing:', err);
    }
  };
  
  const navigateToAction = (listing, action) => {
    if (action === 'search') {
      navigate(`/search/${listing.id}`, { state: { listing } });
    } else if (action === 'upload') {
      navigate(`/upload/${listing.id}`, { state: { listing } });
    }
  };

  return (
    <StyledContainer>
      <Box sx={{ maxWidth: 1200, margin: '0 auto' }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: 800,
              background: 'linear-gradient(135deg, #6FA8DC 0%, #1A365D 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mb: 1,
            }}
          >
            Welcome, {companyName}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4, mt: 4 }}>
            <Typography variant="h5" sx={{ color: '#2B4C8C', fontWeight: 600 }}>
              Job Listings
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <ActionButton
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={() => navigate('/search/all')}
                sx={{ backgroundColor: '#3B6AC1', '&:hover': { backgroundColor: '#2B4C8C' } }}
              >
                Search All Candidates
              </ActionButton>
              <ActionButton
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setDialogOpen(true)}
                sx={{ backgroundColor: '#3B6AC1', '&:hover': { backgroundColor: '#2B4C8C' } }}
              >
                Create Listing
              </ActionButton>
            </Box>
          </Box>

          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          ) : (
            <Grid container spacing={3}>
              {listings.length === 0 ? (
                <Grid item xs={12}>
                  <Typography sx={{ textAlign: 'center', color: '#666' }}>
                    No job listings found. Create one to get started.
                  </Typography>
                </Grid>
              ) : (
                listings.map((listing) => (
                  <Grid item xs={12} md={6} key={listing?.id || Math.random()}>
                    <ListingCard>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Typography variant="h6" sx={{ color: '#2B4C8C', fontWeight: 600 }}>
                          {listing?.title || 'Untitled'}
                        </Typography>
                        <Box>
                          <IconButton
                            onClick={() => handleEditListing(listing)}
                            sx={{ color: '#3B6AC1' }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteClick(listing)}
                            sx={{ color: '#DC2626' }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Box>
                      
                      <Typography 
                        sx={{ 
                          color: '#4A5568', 
                          mb: 2,
                          maxHeight: '100px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {listing?.job_description || 'No description provided'}
                      </Typography>

                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <ActionButton
                          variant="outlined"
                          startIcon={<SearchIcon />}
                          onClick={() => navigateToAction(listing, 'search')}
                          sx={{ flex: 1 }}
                        >
                          Search
                        </ActionButton>
                        <ActionButton
                          variant="outlined"
                          startIcon={<UploadIcon />}
                          onClick={() => navigateToAction(listing, 'upload')}
                          sx={{ flex: 1 }}
                        >
                          Upload
                        </ActionButton>
                      </Box>
                    </ListingCard>
                  </Grid>
                ))
              )}
            </Grid>
          )}
        </motion.div>
      </Box>

      {/* Create/Edit Listing Dialog */}
      <Dialog 
        open={dialogOpen} 
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {editingListing ? 'Edit Job Listing' : 'Create New Job Listing'}
          <IconButton
            onClick={handleCloseDialog}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Job Title"
            fullWidth
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Job Description"
            fullWidth
            multiline
            rows={4}
            value={formData.job_description}
            onChange={(e) => setFormData({ ...formData, job_description: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{ backgroundColor: '#3B6AC1', '&:hover': { backgroundColor: '#2B4C8C' } }}
          >
            {editingListing ? 'Save Changes' : 'Continue to Payment'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Payment Dialog */}
      {paymentIntent && pendingListingData && (
        <Dialog
          open={true}
          onClose={() => dispatch(clearPaymentIntent())}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Complete Payment</DialogTitle>
          <DialogContent>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Payment required: $20.00
            </Typography>
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: paymentIntent,
                appearance: { theme: 'stripe' }
              }}
            >
              <PaymentForm
                onSuccess={() => {
                  dispatch(clearPaymentIntent());
                  handleCloseDialog();
                  dispatch(fetchListings());
                }}
                onError={(error) => {
                  console.error(error);
                }}
                listingData={pendingListingData}
              />
            </Elements>
          </DialogContent>
        </Dialog>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this job listing? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleConfirmDelete}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </StyledContainer>
  );
};

export default Dashboard;