// File: src/App.js

import { Provider } from 'react-redux';
import { store } from './services/store';
import React from 'react';
import Home from './components/Home/Home'; // Updated import path
import { useSelector } from 'react-redux';
import ResultsDisplay from './components/ResultsDisplay/ResultsDisplay';
import ResumeUpload from './components/ResumeUpload/ResumeUpload';
import Dashboard from './components/Dashboard/Dashboard';
import AuthPage from './components/Auth/AuthPage';
import VerificationPage from './components/Auth/VerificationPage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useLocation,
  Outlet,
} from 'react-router-dom';
import {
  Box,
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import '@fontsource/plus-jakarta-sans';
import { logout } from './services/authSlice';
import { useDispatch } from 'react-redux';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2C3E50',
    },
    secondary: {
      main: '#2980B9',
    },
    success: {
      main: '#28a745',
    },
    warning: {
      main: '#ffc107',
    },
    error: {
      main: '#dc3545',
    },
  },
  typography: {
    fontFamily: '"Plus Jakarta Sans", sans-serif',
  },
});

const Navigation = () => {
  const { token, companyName } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isLoggedIn = !!token;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    handleClose();
  };

  // Pages without navigation bar
  const noNavPaths = ['/', '/login', '/signup'];
  if (noNavPaths.includes(location.pathname)) return null;

  // Define button styles
  const buttonStyle = {
    fontWeight: 600,
    fontFamily: '"Plus Jakarta Sans", sans-serif',
    textTransform: 'none',
    fontSize: '28px',
    background: 'linear-gradient(135deg, #6FA8DC 0%, #1A365D 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 0.8,
    },
  };

  // Determine buttons based on the current path
  let leftButtons = [];
  let rightButtons = [];

  if (location.pathname === '/demo') {
    leftButtons.push(
      <Button component={Link} to="/" sx={{ ...buttonStyle, ml: 2 }} key="home">
        Home
      </Button>
    );
    rightButtons.push(
      <Button component={Link} to="/login" sx={{ ...buttonStyle, mr: 2 }} key="login">
        Login
      </Button>
    );
  } else if (location.pathname === '/dashboard') {
    leftButtons.push(
      <Button component={Link} to="/" sx={{ ...buttonStyle, ml: 2 }} key="home">
        Home
      </Button>
    );
    rightButtons.push(
      <Button onClick={handleLogout} sx={{ ...buttonStyle, mr: 2 }} key="logout">
        Logout
      </Button>,
      <IconButton
        onClick={handleMenu}
        sx={{ color: '#1A365D', mr: 2 }}
        key="profile"
      >
        <AccountCircleIcon fontSize="large" />
      </IconButton>
    );
  } else if (
    isLoggedIn &&
    (location.pathname.startsWith('/search') ||
      location.pathname.startsWith('/upload'))
  ) {
    leftButtons.push(
      <Button
        component={Link}
        to="/dashboard"
        sx={{ ...buttonStyle, ml: 2 }}
        key="dashboard"
      >
        Dashboard
      </Button>
    );
    rightButtons.push(
      <Button onClick={handleLogout} sx={{ ...buttonStyle, mr: 2 }} key="logout">
        Logout
      </Button>,
      <IconButton
        onClick={handleMenu}
        sx={{ color: '#1A365D', mr: 2 }}
        key="profile"
      >
        <AccountCircleIcon fontSize="large" />
      </IconButton>
    );
  } else {
    // Default navigation bar
    leftButtons.push(
      <Button component={Link} to="/" sx={{ ...buttonStyle, ml: 2 }} key="home">
        Home
      </Button>
    );
    if (isLoggedIn) {
      rightButtons.push(
        <Button onClick={handleLogout} sx={{ ...buttonStyle, mr: 2 }} key="logout">
          Logout
        </Button>,
        <IconButton
          onClick={handleMenu}
          sx={{ color: '#1A365D', mr: 2 }}
          key="profile"
        >
          <AccountCircleIcon fontSize="large" />
        </IconButton>
      );
    } else {
      rightButtons.push(
        <Button component={Link} to="/login" sx={{ ...buttonStyle, mr: 2 }} key="login">
          Login
        </Button>
      );
    }
  }

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        position: 'absolute',
        top: 0,
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* Left-aligned buttons */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>{leftButtons}</Box>
        {/* Right-aligned buttons */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>{rightButtons}</Box>
      </Toolbar>
      {/* Profile Menu */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem sx={{ fontWeight: 500, color: '#2B4C8C' }}>
          {companyName}
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </AppBar>
  );
};

// Protected Route component
const ProtectedRoute = ({ children }) => {
  const { token } = useSelector((state) => state.auth);
  const location = useLocation();

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

const AppContent = () => {
  const { token } = useSelector((state) => state.auth);

  return (
    <BrowserRouter>
      <Routes>
        {/* Home Page */}
        <Route path="/" element={<Home />} />

        {/* Auth Pages */}
        <Route path="/verify-email/:token" element={<VerificationPage />} />
        <Route path="/login" element={<AuthPage />} />
        <Route path="/signup" element={<AuthPage />} />

        {/* Protected Routes with Navigation */}
        <Route
          element={
            <>
              <Navigation />
              <Outlet />
            </>
          }
        >
          {/* Demo Page */}
          <Route path="/demo" element={<ResultsDisplay />} />

          {/* Protected Routes */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/search/:listingId"
            element={
              <ProtectedRoute>
                <ResultsDisplay />
              </ProtectedRoute>
            }
          />
          <Route
            path="/upload/:listingId"
            element={
              <ProtectedRoute>
                <ResumeUpload />
              </ProtectedRoute>
            }
          />
          {/* Search All Applicants */}
          <Route
            path="/search/all"
            element={
              <ProtectedRoute>
                <ResultsDisplay />
              </ProtectedRoute>
            }
          />
        </Route>

        {/* Catch-all Route */}
        <Route
          path="*"
          element={
            token ? (
              <Navigate to="/dashboard" replace />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

// Main App component
function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppContent />
      </ThemeProvider>
    </Provider>
  );
}

export default App;