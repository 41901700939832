import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Box,
  LinearProgress,
  InputAdornment,
  IconButton,
  Drawer,
  Paper,
  useMediaQuery,
  CircularProgress,
  useTheme,
  AppBar, 
  Toolbar,
  Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { generateWeights, searchCandidates } from '../../services/searchSlice';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import TuneIcon from '@mui/icons-material/Tune';
import '@fontsource/plus-jakarta-sans'; 
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import WeightSliders from '../Settings/WeightSliders';
import { useLocation, useParams } from 'react-router-dom';


const StyledContainer = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg, #f8faff 0%, #f0f4fb 100%)',
  minHeight: '100vh',
  padding: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontFamily: '"Plus Jakarta Sans", sans-serif',
  overflowX: 'hidden',
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1200px',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
}));

const GradientTitle = styled(Typography)(({ theme }) => ({
  fontSize: '96px',
  [theme.breakpoints.down('md')]: {
    fontSize: '72px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '42px',
    padding: theme.spacing(0, 1),
  },
  fontWeight: 800,
  background: 'linear-gradient(135deg, #6FA8DC 0%, #1A365D 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  marginBottom: theme.spacing(1),
  fontFamily: '"Plus Jakarta Sans", sans-serif',
  letterSpacing: '-2px',
  width: '100%',
  wordWrap: 'break-word',
}));

const StyledCatchline = styled(Typography)(({ theme }) => ({
  fontSize: '28px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
    padding: theme.spacing(0, 1),
    marginBottom: theme.spacing(3),
  },
  fontWeight: 500,
  background: 'linear-gradient(135deg, #4A90E2 0%, #1A365D 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  marginBottom: theme.spacing(4),
  fontFamily: '"Plus Jakarta Sans", sans-serif',
  letterSpacing: '0.5px',
  width: '100%',
  wordWrap: 'break-word',
}));

const SearchContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(0, 2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0),
    width: 'calc(100% - 32px)',
    margin: '0 auto',
    marginBottom: theme.spacing(2),
  },
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: '#DC2626',
  fontSize: '14px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    paddingLeft: '8px',
  },
  marginBottom: theme.spacing(6),
  fontFamily: '"Plus Jakarta Sans", sans-serif',
  textAlign: 'left',
  paddingLeft: '16px',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '16px',
    backgroundColor: 'white',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
    transition: 'all 0.3s ease',
    fontFamily: '"Plus Jakarta Sans", sans-serif',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      padding: theme.spacing(1),
    },
    '&:hover': {
      boxShadow: '0 6px 8px rgba(0, 0, 0, 0.1)',
    },
    '&.Mui-focused': {
      boxShadow: '0 8px 12px rgba(0, 0, 0, 0.1)',
    },
  },
}));

const ResultCard = styled(Paper)(({ theme, score }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 1, 2.5, 1),
    width: 'calc(100% - 16px)',
  },
  marginBottom: theme.spacing(2.5),
  borderRadius: '16px',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
  alignItems: 'center',
  gap: theme.spacing(2),
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
  transition: 'all 0.3s ease',
  position: 'relative',
  overflow: 'hidden',
  '&:hover': {
    boxShadow: '0 8px 12px rgba(0, 0, 0, 0.1)',
    transform: 'translateY(-2px)',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: '4px',
    background: getGradientByScore(score),
  },
}));

const ScoreChip = styled(Box)(({ theme, score }) => ({
  width: '65px',
  height: '65px',
  [theme.breakpoints.down('sm')]: {
    width: '45px',
    height: '45px',
    fontSize: '14px',
  },
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 600,
  fontSize: '18px',
  fontFamily: '"Plus Jakarta Sans", sans-serif',
  color: 'white',
  background: getGradientByScore(score),
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '80%',
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    maxWidth: '800px',
    backgroundColor: '#f8faff',
    overflowY: 'auto',
    overflowX: 'hidden',
    // Remove default padding from drawer paper
    padding: 0,
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#3B6AC1',
      borderRadius: '4px',
      '&:hover': {
        background: '#2B4C8C',
      },
    },
  },
}));

// Drawer header styles
const DrawerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'sticky',
  top: 0,
  backgroundColor: '#f8faff',
  zIndex: 1,
  padding: theme.spacing(2),
  borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
}));

// Main content container
const DrawerContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

// Project card styles
const ProjectCard = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: '10px',
  padding: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
  marginBottom: theme.spacing(2),
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  '&:last-child': {
    marginBottom: 0,
  },
}));

const getGradientByScore = (score) => {
  if (score >= 75) {
    return 'linear-gradient(135deg, #3B6AC1 0%, #2B4C8C 100%)'; // Dark blue gradient for high scores
  } else if (score >= 35) {
    return 'linear-gradient(135deg, #2B4C8C 0%, #1A365D 100%)'; // Slightly darker blue for medium scores
  }
  return 'linear-gradient(135deg, #1A365D 0%, #0F2442 100%)'; // Darkest blue for low scores
};

const ResultsDisplay = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const { candidates, weightsLoading, searchLoading, error, key_experiences } = useSelector((state) => state.search);
  const [jobDescription, setJobDescription] = useState('');
  const [processedCandidates, setProcessedCandidates] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [adjustedWeights, setAdjustedWeights] = useState([]);
  const [wordCount, setWordCount] = useState(0);
  const [showError, setShowError] = useState(false);
  const [searchProgress, setSearchProgress] = useState(0);
  const [showSearchProgress, setShowSearchProgress] = useState(false);
  const location = useLocation();
  const { listingId: urlListingId } = useParams();
  const listing = location.state?.listing;
  const listingId = urlListingId || listing?.id;
  const isAllSearch = urlListingId === 'all';


  useEffect(() => {
    setProcessedCandidates(candidates);
  }, [candidates]);

  useEffect(() => {
    if (!searchLoading) {
      setShowSearchProgress(false);
      setSearchProgress(0);
      return;
    }
  }, [searchLoading]);

  // pseudo loading bar
  useEffect(() => {
    if (!showSearchProgress) return;

    const duration = 40000; // 40 seconds
    const targetProgress = 97;
    const interval = 100; // Update every 100ms
    const incrementPerInterval = (targetProgress / (duration / interval));

    const timer = setInterval(() => {
      setSearchProgress(prev => {
        if (prev >= targetProgress) {
          clearInterval(timer);
          return targetProgress;
        }
        return Math.min(prev + incrementPerInterval, targetProgress);
      });
    }, interval);

    return () => clearInterval(timer);
  }, [showSearchProgress]);

  useEffect(() => {
    if (listing?.job_description) {
      setJobDescription(listing.job_description);
      setWordCount(countWords(listing.job_description));
    }
  }, [listing]);

  const handleWeightsChange = (newWeights) => {
    setAdjustedWeights(newWeights);
  };

  const countWords = (text) => {
    return text.trim().split(/\s+/).filter(word => word.length > 0).length;
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setJobDescription(newText);
    setWordCount(countWords(newText));
    setShowError(false); // Reset error when text changes
  };

  const handleGenerateWeights = async () => {
    const currentWordCount = countWords(jobDescription);
    if (currentWordCount < 20) {
      setShowError(true);
      return;
    }
    
    if (jobDescription.trim() === '') {
      alert('Please enter a job description.');
      return;
    }
    
    await dispatch(generateWeights(jobDescription));
  };

  const handleSearch = async () => {
    if (jobDescription.trim() === '') {
      alert('Please enter a job description.');
      return;
    }
    
    let weightsToUse = adjustedWeights.length > 0 ? adjustedWeights : key_experiences;
    
    const totalWeight = weightsToUse.reduce((sum, w) => sum + w[1], 0);
    if (totalWeight > 0) {
      weightsToUse = weightsToUse.map(w => [w[0], w[1] / totalWeight]);
    } else {
      alert('Total weight must be greater than 0.');
      return;
    }

    setShowSearchProgress(true);
    setSearchProgress(0);
    
    dispatch(searchCandidates({
      query: jobDescription,
      weights: weightsToUse,
      listingId: isAllSearch ? 'all' : listingId
    }));
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleGenerateWeights();
    }
  };

  const handleCandidateClick = (candidate) => {
    setSelectedCandidate(candidate);
    setDrawerOpen(true);
  };

  return (
    <StyledContainer>
      <ContentWrapper>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <GradientTitle>
            {isAllSearch ? 'Search All Applicants' : 'Talent Screener'}
          </GradientTitle>
          <StyledCatchline>
            {isAllSearch ? 'Search through all applicants across job listings' : 'Find your next hire!'}
          </StyledCatchline>
        </motion.div>

        {/* Job Description Input */}
        <SearchContainer>
          <StyledTextField
            variant="outlined"
            placeholder="Enter Job Description"
            multiline
            minRows={1}
            value={jobDescription}
            onChange={handleTextChange}
            onKeyPress={handleKeyPress}
            error={showError}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleGenerateWeights}
                    edge="end"
                    disabled={weightsLoading || wordCount < 20}
                    sx={{ 
                      color: wordCount < 20 ? '#9CA3AF' : '#3B6AC1',
                      '&:hover': {
                        backgroundColor: wordCount < 20 ? 'transparent' : 'rgba(59, 106, 193, 0.1)',
                      }
                    }}
                  >
                    {weightsLoading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <TuneIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {(showError || wordCount > 0) && (
            <ErrorMessage>
              {wordCount < 20 
                ? `For the best results, please enter at least 20 words (current: ${wordCount})`
                : `Words: ${wordCount}`}
            </ErrorMessage>
          )}
        </SearchContainer>

        {/* Loading indicator for weights generation */}
        {weightsLoading && (
          <Box sx={{ width: '100%', maxWidth: '800px', margin: '0 auto', marginBottom: 2 }}>
            <Typography 
              sx={{ 
                marginBottom: 1,
                color: '#3B6AC1',
                fontFamily: '"Plus Jakarta Sans", sans-serif',
                fontSize: '14px'
              }}
            >
              Generating search criteria...
            </Typography>
            <LinearProgress 
              sx={{ 
                height: 6, 
                borderRadius: 3,
                backgroundColor: 'rgba(59, 106, 193, 0.1)',
                '& .MuiLinearProgress-bar': {
                  background: 'linear-gradient(135deg, #3B6AC1 0%, #2B4C8C 100%)',
                }
              }} 
            />
          </Box>
        )}

        {/* WeightSliders component with IntraSearch button */}
        {key_experiences && key_experiences.length > 0 && (
          <WeightSliders 
            experiences={key_experiences} 
            onWeightsChange={handleWeightsChange}
            onSearch={handleSearch}
            searchLoading={searchLoading}
          />
        )}

        {/* Search progress bar */}
        {showSearchProgress && (
          <Box sx={{ width: '100%', maxWidth: '800px', margin: '0 auto', marginBottom: 2 }}>
            <Typography 
              sx={{ 
                marginBottom: 1,
                color: '#3B6AC1',
                fontFamily: '"Plus Jakarta Sans", sans-serif',
                fontSize: '14px'
              }}
            >
              Searching candidates...
            </Typography>
            <LinearProgress 
              variant="determinate" 
              value={searchProgress}
              sx={{ 
                height: 6, 
                borderRadius: 3,
                backgroundColor: 'rgba(59, 106, 193, 0.1)',
                '& .MuiLinearProgress-bar': {
                  background: 'linear-gradient(135deg, #3B6AC1 0%, #2B4C8C 100%)',
                }
              }} 
            />
          </Box>
        )}

        {/* Display error if any */}
        {error && (
          <Typography 
            color="error" 
            sx={{ 
              marginBottom: 2, 
              textAlign: 'center',
              fontFamily: '"Plus Jakarta Sans", sans-serif'
            }}
          >
            {error}
          </Typography>
        )}

        {/* Display search results */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          {processedCandidates.map((candidate) => (
            <ResultCard
              key={candidate.name}
              score={candidate.score}
              elevation={0}
            >
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                width: '100%',
                gap: 2,
                justifyContent: isMobile ? 'space-between' : 'flex-start'
              }}>
                <Typography
                  sx={{
                    width: isMobile ? 'auto' : '60px',
                    fontWeight: 600,
                    color: '#3B6AC1',
                    textAlign: 'center',
                    fontFamily: '"Plus Jakarta Sans", sans-serif',
                    fontSize: isMobile ? '16px' : '18px',
                  }}
                >
                  #{candidate.rank}
                </Typography>
                
                <Typography
                  sx={{
                    flex: isMobile ? 1 : 2,
                    fontWeight: 600,
                    color: '#2B4C8C',
                    textAlign: 'left',
                    paddingLeft: isMobile ? 0 : 3,
                    fontFamily: '"Plus Jakarta Sans", sans-serif',
                    fontSize: isMobile ? '14px' : '16px',
                  }}
                >
                  {candidate.name}
                </Typography>

                {!isMobile && (
                  <Typography
                    sx={{
                      flex: 3,
                      color: '#4A5568',
                      textAlign: 'left',
                      paddingLeft: 3,
                      paddingRight: 3,
                      fontFamily: '"Plus Jakarta Sans", sans-serif',
                      fontSize: '15px',
                    }}
                  >
                    {candidate.match_reason}
                  </Typography>
                )}
              </Box>

              {isMobile && (
                <Typography
                  sx={{
                    width: '100%',
                    color: '#4A5568',
                    textAlign: 'left',
                    fontFamily: '"Plus Jakarta Sans", sans-serif',
                    fontSize: '14px',
                    marginTop: 1,
                  }}
                >
                  {candidate.match_reason}
                </Typography>
              )}

              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 2,
                marginLeft: isMobile ? 0 : 'auto'
              }}>
                <ScoreChip score={candidate.score}>
                  {candidate.score}%
                </ScoreChip>
                
                <IconButton
                  onClick={() => handleCandidateClick(candidate)}
                  sx={{ 
                    color: '#3B6AC1',
                    '&:hover': {
                      backgroundColor: 'rgba(59, 106, 193, 0.1)',
                    }
                  }}
                >
                  <KeyboardArrowRightIcon />
                </IconButton>
              </Box>
            </ResultCard>
          ))}
        </motion.div>
      </ContentWrapper>

      {/* Candidate Details Drawer */}
      <StyledDrawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        {selectedCandidate && (
          <>
            <DrawerHeader>
              <IconButton
                onClick={() => setDrawerOpen(false)}
                sx={{ 
                  marginRight: 2,
                  color: '#3B6AC1',
                  '&:hover': {
                    backgroundColor: 'rgba(59, 106, 193, 0.1)',
                  }
                }}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <Typography
                variant="h5"
                sx={{
                  fontFamily: '"Plus Jakarta Sans", sans-serif',
                  fontWeight: 600,
                  background: 'linear-gradient(135deg, #6FA8DC 0%, #1A365D 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  fontSize: {
                    xs: '20px',
                    sm: '24px',
                  },
                }}
              >
                {selectedCandidate.name}
              </Typography>
            </DrawerHeader>

            <DrawerContent>
              <Typography
                sx={{
                  fontFamily: '"Plus Jakarta Sans", sans-serif',
                  color: '#2B4C8C',
                  fontSize: {
                    xs: '14px',
                    sm: '16px',
                  },
                  fontWeight: 500,
                  marginBottom: theme.spacing(3),
                }}
              >
                {selectedCandidate.match_reason}
              </Typography>

              {selectedCandidate.projects.map((project, index) => (
                <ProjectCard key={index}>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    marginBottom: 2,
                    gap: 2,
                  }}>
                    <ScoreChip 
                      score={project.score}
                      sx={{
                        [theme.breakpoints.down('sm')]: {
                          width: '40px',
                          height: '40px',
                          fontSize: '14px',
                        }
                      }}
                    >
                      {project.score}%
                    </ScoreChip>
                    <Typography
                      sx={{
                        fontFamily: '"Plus Jakarta Sans", sans-serif',
                        fontWeight: 600,
                        color: '#1A365D',
                        fontSize: {
                          xs: '16px',
                          sm: '18px',
                        },
                      }}
                    >
                      {project.project_name || `Project ${index + 1}`}
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      fontFamily: '"Plus Jakarta Sans", sans-serif',
                      fontWeight: 500,
                      color: '#2B4C8C',
                      marginBottom: 2,
                      fontSize: {
                        xs: '14px',
                        sm: '15px',
                      },
                    }}
                  >
                    {project.match_reason}
                  </Typography>

                  <Typography
                    sx={{
                      fontFamily: '"Plus Jakarta Sans", sans-serif',
                      color: '#4A5568',
                      fontSize: {
                        xs: '13px',
                        sm: '14px',
                      },
                      lineHeight: 1.6,
                    }}
                  >
                    {project.description}
                  </Typography>
                </ProjectCard>
              ))}
            </DrawerContent>
          </>
        )}
      </StyledDrawer>
    </StyledContainer>
  );
};

export default ResultsDisplay;