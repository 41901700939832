import React, { useState, useEffect } from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  Slider, 
  Box, 
  Paper, 
  TextField, 
  Button, 
  CircularProgress,
  IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: '800px',
  margin: '24px auto',
  background: '#ffffff',
  borderRadius: '16px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
  '&:hover': {
    boxShadow: '0 6px 8px rgba(0, 0, 0, 0.1)',
  },
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: '#3B6AC1',
  '& .MuiSlider-thumb': {
    '&:hover, &.Mui-focusVisible': {
      boxShadow: '0 0 0 8px rgba(59, 106, 193, 0.16)',
    },
  },
  '& .MuiSlider-rail': {
    opacity: 0.32,
  },
}));

const WeightSliders = ({ experiences, onWeightsChange, onSearch, searchLoading }) => {
  const [weights, setWeights] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (experiences && experiences.length > 0) {
      setWeights(experiences.map(exp => ({
        description: exp[0],
        weight: Math.round(exp[1] * 100),
      })));
      setHasChanges(false);
    }
  }, [experiences]);

  const handleWeightChange = (index, newValue) => {
    const newWeights = [...weights];
    if (typeof newValue === 'string') {
      const cleanValue = newValue.replace(/\./g, '');
      newWeights[index].weight = cleanValue === '' ? '' : parseInt(cleanValue) || 0;
    } else {
      newWeights[index].weight = Math.round(newValue);
    }
    updateWeights(newWeights);
  };

  const handleDescriptionChange = (index, newDescription) => {
    const newWeights = [...weights];
    newWeights[index].description = newDescription;
    updateWeights(newWeights);
  };

  const handleAddCriteria = () => {
    const newWeights = [...weights, {
      description: "New criteria",
      weight: 0
    }];
    updateWeights(newWeights);
  };

  const handleDeleteCriteria = (index) => {
    const newWeights = weights.filter((_, i) => i !== index);
    updateWeights(newWeights);
  };

  const updateWeights = (newWeights) => {
    setWeights(newWeights);
    setHasChanges(true);

    if (onWeightsChange) {
      const normalizedWeights = newWeights.map(w => [
        w.description, 
        w.weight === '' ? 0 : w.weight / 100
      ]);
      onWeightsChange(normalizedWeights);
    }
  };

  const handleBlur = (index) => {
    const newWeights = [...weights];
    if (newWeights[index].weight === '') {
      newWeights[index].weight = 0;
      updateWeights(newWeights);
    }
  };

  return (
    <StyledCard>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              color: '#2B4C8C',
              fontFamily: '"Plus Jakarta Sans", sans-serif',
            }}
          >
            Ideal Candidate Criteria
          </Typography>
          
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {hasChanges && (
              <Typography
                sx={{
                  fontSize: '0.875rem',
                  color: '#3B6AC1',
                  fontFamily: '"Plus Jakarta Sans", sans-serif',
                  fontStyle: 'italic'
                }}
              >
                Changes pending
              </Typography>
            )}
            <IconButton
              onClick={handleAddCriteria}
              sx={{
                color: '#3B6AC1',
                '&:hover': {
                  backgroundColor: 'rgba(59, 106, 193, 0.1)',
                }
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {weights.map((weight, index) => (
            <Paper
              key={index}
              elevation={0}
              sx={{
                p: 2,
                backgroundColor: 'rgba(248, 250, 255, 0.8)',
                borderRadius: 2,
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, alignItems: 'center', gap: 2 }}>
                <TextField
                  value={weight.description}
                  onChange={(e) => handleDescriptionChange(index, e.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      fontSize: '0.875rem',
                      color: '#4A5568',
                      fontFamily: '"Plus Jakarta Sans", sans-serif',
                    }
                  }}
                />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <TextField
                    value={weight.weight}
                    onChange={(e) => handleWeightChange(index, e.target.value)}
                    onBlur={() => handleBlur(index)}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      inputProps: { 
                        min: 0, 
                        max: 100,
                        step: 1
                      },
                      endAdornment: <Typography>%</Typography>,
                    }}
                    type="number"
                    sx={{ width: '80px' }}
                  />
                  <IconButton
                    onClick={() => handleDeleteCriteria(index)}
                    sx={{
                      color: '#DC2626',
                      '&:hover': {
                        backgroundColor: 'rgba(220, 38, 38, 0.1)',
                      }
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
              <StyledSlider
                value={weight.weight === '' ? 0 : weight.weight}
                onChange={(_, value) => handleWeightChange(index, value)}
                step={1}
                min={0}
                max={100}
                aria-label="Weight"
              />
            </Paper>
          ))}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Button
            variant="contained"
            onClick={onSearch}
            disabled={searchLoading}
            startIcon={searchLoading ? <CircularProgress size={20} /> : <SearchIcon />}
            sx={{
              backgroundColor: '#3B6AC1',
              '&:hover': {
                backgroundColor: '#2B4C8C',
              },
              borderRadius: '12px',
              padding: '10px 24px',
              textTransform: 'none',
              fontFamily: '"Plus Jakarta Sans", sans-serif',
              fontWeight: 600,
            }}
          >
            IntraSearch
          </Button>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default WeightSliders;