// File: src/components/Auth/AuthPage.js

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
  InputAdornment,
  IconButton,
  AppBar,
  Toolbar,
} from '@mui/material';
import { styled } from '@mui/system';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { login, signup, verifyEmail, clearSignupSuccess } from '../../services/authSlice';
import '@fontsource/plus-jakarta-sans';
import backgroundVideo from '../../assets/background.mov';

const BackgroundContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  minHeight: '100vh',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column', // To accommodate the AppBar
  fontFamily: '"Plus Jakarta Sans", sans-serif',
}));

const VideoBackground = styled('video')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '100%',
  minHeight: '100%',
  width: 'auto',
  height: 'auto',
  zIndex: -1,
  transform: 'translate(-50%, -50%)',
  objectFit: 'cover',
});

const FormContainer = styled(Box)(({ theme }) => ({
  maxWidth: 400,
  width: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.85)', // Increased opacity for better readability
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
  zIndex: 1,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5),
  borderRadius: '8px',
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '16px',
  backgroundColor: '#1A365D',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#16305A',
  },
}));

const ToggleLink = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
  textAlign: 'center',
  cursor: 'pointer',
  color: '#1A365D',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const AuthPage = () => {
  const [isSignup, setIsSignup] = useState(false); // Using local state to toggle between login and signup
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const verificationSuccess = location.state?.verificationSuccess;
  const verificationMessage = location.state?.message;

  const { loading, error, signupSuccess, signupMessage, token } = useSelector((state) => state.auth);

  // Redirect if already logged in
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && !signupSuccess) {
      navigate('/dashboard');
    }
  }, [navigate, token, signupSuccess]);

  // clear location state after showing message
  useEffect(() => {
    if (verificationSuccess) {
      const timer = setTimeout(() => {
        navigate('.', { replace: true, state: {} });
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [verificationSuccess, navigate]);

  useEffect(() => {
    return () => {
      dispatch(clearSignupSuccess());
    };
  }, [dispatch]);

  const validateForm = () => {
    let errors = {};

    if (!formData.name) {
      errors.name = 'Company name is required';
    }

    if (!formData.password) {
      errors.password = 'Password is required';
    }

    if (isSignup) {
      if (!formData.email) {
        errors.email = 'Email is required';
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        errors.email = 'Invalid email address';
      }
      
      if (formData.password !== formData.confirmPassword) {
        errors.confirmPassword = "Passwords don't match";
      }
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const action = isSignup ? signup : login;
      await dispatch(
        action({
          name: formData.name,
          password: formData.password,
          email: formData.email
        })
      ).unwrap();
    } catch (err) {
      console.error('Authentication error:', err);
    }
  };

  const toggleMode = () => {
    setIsSignup(!isSignup);
    setFormErrors({});
    setFormData({
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
    });
    dispatch(clearSignupSuccess());
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <BackgroundContainer>
      {/* Background Video */}
      <VideoBackground autoPlay muted loop>
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </VideoBackground>

      {/* Navigation Bar */}
      <AppBar
        elevation={0}
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          position: 'absolute',
          top: 0,
        }}
      >
        <Toolbar sx={{ justifyContent: 'flex-start' }}>
          {/* Home Button */}
          <Button
            component={Link}
            to="/"
            sx={{
              fontWeight: 600,
              fontFamily: '"Plus Jakarta Sans", sans-serif',
              textTransform: 'none',
              ml: 2,
              fontSize: '28px',
              background: 'linear-gradient(135deg, #6FA8DC 0%, #1A365D 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
                opacity: 0.8,
              },
            }}
          >
            Home
          </Button>
        </Toolbar>
      </AppBar>

      {/* Login Form */}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FormContainer>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              marginBottom: 2,
              textAlign: 'center',
              color: '#1A365D',
              fontFamily: '"Plus Jakarta Sans", sans-serif',
            }}
          >
            {isSignup ? 'Sign Up' : 'Login'}
          </Typography>

          {error && (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              {error}
            </Alert>
          )}

          {verificationSuccess && (
            <Alert severity="success" sx={{ marginBottom: 2 }}>
              {verificationMessage}
            </Alert>
          )}

          {signupSuccess ? (
            <Alert severity="success" sx={{ marginBottom: 2 }}>
              {signupMessage}
            </Alert>
          ) : (
            <form onSubmit={handleSubmit}>
              <StyledTextField
                fullWidth
                label="Company Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                error={!!formErrors.name}
                helperText={formErrors.name}
                variant="outlined"
                InputProps={{
                  sx: { textTransform: 'lowercase' },
                }}
              />
              {isSignup && (
                <StyledTextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                />
              )}
              <StyledTextField
                fullWidth
                label="Password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={formData.password}
                onChange={handleChange}
                error={!!formErrors.password}
                helperText={formErrors.password}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {isSignup && (
                <StyledTextField
                  fullWidth
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  error={!!formErrors.confirmPassword}
                  helperText={formErrors.confirmPassword}
                  variant="outlined"
                />
              )}

              <SubmitButton
                fullWidth
                variant="contained"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : isSignup ? (
                  'Sign Up'
                ) : (
                  'Login'
                )}
              </SubmitButton>
            </form>
          )}

          {!signupSuccess && (
            <ToggleLink variant="body2" onClick={toggleMode}>
              {isSignup
                ? 'Already have an account? Login'
                : "Don't have an account? Sign Up"}
            </ToggleLink>
          )}
        </FormContainer>
      </Box>
    </BackgroundContainer>
  );
};

export default AuthPage;