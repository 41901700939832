import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from './api';

export const fetchListings = createAsyncThunk(
  'listings/fetchListings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getListings();
      return response.listings;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to fetch listings');
    }
  }
);

export const initiateListingCreation = createAsyncThunk(
  'listings/initiateListingCreation',
  async (listingData, { rejectWithValue }) => {
    try {
      const response = await api.handleListingCreation(listingData);
      return {
        clientSecret: response.clientSecret,
        listingData
      };
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to initiate listing creation');
    }
  }
);

export const completeListingCreation = createAsyncThunk(
  'listings/completeListingCreation',
  async ({ paymentIntentId, listingData }, { rejectWithValue }) => {
    try {
      const response = await api.confirmPaymentAndCreateListing(paymentIntentId, listingData);
      return {
        id: response.listing_id,
        title: listingData.title,
        job_description: listingData.job_description,
        namespace: listingData.namespace || 'all',
        payment_status: 'completed'
      };
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to complete listing creation');
    }
  }
);

export const updateListing = createAsyncThunk(
  'listings/updateListing',
  async ({ listingId, listingData }, { rejectWithValue }) => {
    try {
      const response = await api.updateListing(listingId, listingData);
      return { id: listingId, ...listingData };
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to update listing');
    }
  }
);

export const deleteListing = createAsyncThunk(
  'listings/deleteListing',
  async (listingId, { rejectWithValue }) => {
    try {
      await api.deleteListing(listingId);
      return listingId;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to delete listing');
    }
  }
);

const listingsSlice = createSlice({
  name: 'listings',
  initialState: {
    list: [],
    selectedListing: null,
    loading: false,
    error: null,
    paymentIntent: null,
    pendingListingData: null,
    paymentStatus: null
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    setSelectedListing: (state, action) => {
      state.selectedListing = action.payload;
    },
    clearPaymentIntent: (state) => {
      state.paymentIntent = null;
      state.pendingListingData = null;
      state.paymentStatus = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch listings cases
      .addCase(fetchListings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchListings.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(fetchListings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      // Initiate listing creation cases
      .addCase(initiateListingCreation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.paymentStatus = 'pending';
      })
      .addCase(initiateListingCreation.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentIntent = action.payload.clientSecret;
        state.pendingListingData = action.payload.listingData;
        state.paymentStatus = 'awaiting_payment';
      })
      .addCase(initiateListingCreation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.paymentStatus = 'failed';
      })
      
      // Complete listing creation cases
      .addCase(completeListingCreation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(completeListingCreation.fulfilled, (state, action) => {
        state.loading = false;
        state.list.push(action.payload);
        state.paymentIntent = null;
        state.pendingListingData = null;
        state.paymentStatus = 'completed';
      })
      .addCase(completeListingCreation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.paymentStatus = 'failed';
      })
      
      // Update listing cases
      .addCase(updateListing.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateListing.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.list.findIndex(listing => listing.id === action.payload.id);
        if (index !== -1) {
          state.list[index] = action.payload;
        }
      })
      .addCase(updateListing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      // Delete listing cases
      .addCase(deleteListing.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteListing.fulfilled, (state, action) => {
        state.loading = false;
        state.list = state.list.filter(listing => listing.id !== action.payload);
      })
      .addCase(deleteListing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { clearError, setSelectedListing, clearPaymentIntent } = listingsSlice.actions;
export default listingsSlice.reducer;